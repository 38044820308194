/*
 * Copyright 2017 General Code
 */

if (!window.generalcode) {
  window.generalcode = {};
}

var EventCategory = {
  INDEX: "Index",
  PRINT: "Print",
  SHARE: "Share",
  SUBSCRIPTION: "Subscription"
};

var EventName = {
  INDEX_USE: "IndexUse",
  PRINT_CONTENT: "PrintContent",
  SHARE_CONTENT_FACEBOOK: "ShareContentFacebook",
  SHARE_CONTENT_TWITTER: "ShareContentTwitter",
  SHARE_CONTENT_EMAIL: "ShareContentEmail",
  SUBSCRIPTION_QUESTION_ASKED: "SubscriptionQuestionAsked",
  SUBSCRIPTION_QUESTION_DECLINED: "SubscriptionQuestionDeclined"
};

var selectedGuids = [];
var contentTitles = {};
var getSelectedGuids = function () {
  return selectedGuids;
};

var $window = $(window);
var $body = $("body");
var $warningsBar = $("#warnings");
var $searchBar = $("#search-bar");
var $breadcrumbs = $("#breadcrumbs");
var $toolbarContainer = $("#toolbarArea");
var $codeContent = $("#codeContent");
var $codeChildContent = $("#childContent");
var $tocContent = $("#toc");
var $versionSelector = $("#version-selector");
var $versionInfoIcon = $("#versionInfoIcon");
var pageGuid = $body.data("guid") + "";
var custId = $body.data("custId");
var codeId = $body.data("codeId");
var customerName = $body.data("customerName");
var isArchive = (codeId !== $body.data("currentCodeId"));
var chapterTitle = $body.data("chapterString");
var user = $body.data("user");
var $printToolbarButton = $("#printButton");
var $emailToolbarButton = $("#sendButton");
var $downloadToolbarButton = $("#downloadButton");
var $shareToolbarButton = $("#shareButton");
var $alertsToolbarButton = $("#followButton");
var $createQuestionToolbarButton = $("#createQuestion");
var urlSchema = $body.data("urlSchema");
var codeUrl = urlSchema + "://" + window.location.hostname + (isArchive ? ("/" + codeId) : "/");
var eventService = new EventService();
var originalPageTitle = document.title;
var originalPathname = document.location.pathname;
var findXlate = 3;

var tbarObserver = new MutationObserver(function (event) {
  if(document.documentElement.className.match('translated')) {
    $('html').addClass("goog-tbar");
  } else {
    var tbar = $("div.skiptranslate > iframe.skiptranslate").parent();
    if (tbar) {
      var tbarStyle = tbar.attr('style');
      if (tbarStyle && tbarStyle.includes('none')) {
        $('html').removeClass('goog-tbar');
      }
    } else {
      $('html').removeClass('goog-tbar');
    }
  }
});


// Watch the class attribue of the html tag for changes
tbarObserver.observe(document.documentElement, {
  attributes: true,
  attributeFilter: ['class'],
  childList: false,
  characterData: false
});


function setupTbarObserver() {
  // find the iframe that houses the translate widget in the footer
  var t1 = $("iframe.skiptranslate")[0];
  if (t1) {
    // watch for when the translate widget changes the style attribute of that frame
    tbarObserver.observe(t1, {
      attributes: true,
      attributeFilter: ['style'],
      childList: false,
      characterData: false
    });
  } else {
    // if the iframe from the translate widget is not there yet...
    if (findXlate > 0) {
      findXlate--;
      // try again
      setTimeout(setupTbarObserver, 500);
    }
    // After 3 tries give up
  }
}

if (document.readyState === "loading") {
  // Loading hasn't finished yet
  document.addEventListener("DOMContentLoaded", setupTbarObserver);
} else {
  // `DOMContentLoaded` has already fired
  setupTbarObserver();
}

getSelectedGuids = function () {
  if (selectedGuids.length === 0) {
    return [pageGuid];
  } else {
    return selectedGuids;
  }
};

var getPrintUrl = function (guids) {
  if (!guids) {
    guids = getSelectedGuids();
  }
  return "/print/" + (isArchive ? codeId : custId) + "?guid=" + guids.join();
};
window.generalcode.getPrintUrl = getPrintUrl;
$printToolbarButton.attr("href", getPrintUrl());

var getShareItemContent = function (guid) {
  var title = contentTitles[guid];
  return (chapterTitle && chapterTitle !== title ? encodeURIComponent(chapterTitle) + "%0A" : "") +
      encodeURIComponent(title) + "%0A" + codeUrl + guid + "%0A%0A";
};

var getEmailUrl = function (guids) {
  if (!guids) {
    guids = getSelectedGuids();
  }
  var archiveBodyString = "";
  if (isArchive) {
    archiveBodyString = "Archive " + $body.data("codeDate") + "%0A";
  }
  var emailHref = "mailto:" +
      "?subject=" + encodeURIComponent(customerName) +
      "&body=" + encodeURIComponent(customerName) + " Code%0A" +
      archiveBodyString + "%0A";
  $.each(guids, function (index, guid) {
    emailHref += getShareItemContent(guid);
  });
  return emailHref;
};
window.generalcode.getEmailUrl = getEmailUrl;
$emailToolbarButton.attr("href", getEmailUrl());

var getDownloadUrl = function (type, guids) {
  if (!guids) {
    guids = getSelectedGuids();
  }
  var url = "/output/word/" + guids.join();
  if (type === "xml") {
    url = '/output/source/' + guids[0];
  }
  if (isArchive) {
    url = url + "/" + codeId;
  }
  return url.replace("/word/", "/" + type + "/");
};

var getTitle = function (guid) {
  var title = "";
  var $titleElement = $("#" + guid + ",#" + guid + "_title");
  if ($titleElement.length > 0) {
    if ($titleElement.hasClass("litem")) {
      var $titleNumber = $titleElement.find(".litem_number");
      if ($titleNumber.length > 0) {
        title = "§ " + $titleNumber.attr("title");
      }
      var $title = $titleElement.find(">.title,>.litem_content>.title");
      if ($title.length > 0 && $title.text().trim()) {
        title += " " + $title.text();
      }
    } else if ($titleElement.hasClass("defitem")) {
      var $definitionNumber = $titleElement.find("> .defitem_number");
      if ($definitionNumber.length > 0) {
        title += $definitionNumber.text() + " ";
      }
      var $definitionTerm = $titleElement.find(".term");
      if ($definitionTerm.length > 0) {
        title += $definitionTerm.text();
      }
    } else if ($titleElement.data("full-title")) {
      title = $titleElement.data("full-title");
    } else {
      title = $titleElement.text();
    }
  } else if (pageGuid === guid) {
    title = $body.data("pageString") ? $body.data("pageString")
        : ($body.data("longPageString") ? $body.data("longPageString") : $body.data("customerName"));
  }
  return title ? title.replace("\\", "/") : "";
};
contentTitles[pageGuid] = getTitle(pageGuid);

var selectHiddenCheckbox = function (guid, isSelected) {
  var $hiddenInput = $("#" + guid + "_input");
  if ($hiddenInput.length > 0) {
    if ($hiddenInput[0].checked !== isSelected) {
      $hiddenInput[0].checked = isSelected;
      $hiddenInput[0].dispatchEvent(new Event("change"));
    }
  }
};

var handleSelectionClick = function (e) {
  var $clickedTitle = $(e.target).closest(".contentTitle");
  var shouldBeSelected = !$clickedTitle.hasClass("selected");
  var titleGuid = $clickedTitle.data("guid");
  var affectedGuids = [titleGuid];
  var addedGuids = [];
  var removedGuids = [];
  if (shouldBeSelected) {
    addedGuids = [titleGuid];
  } else {
    removedGuids = [titleGuid];
  }

  // Check if there are any child checkboxes that would also be affected by the click
  var subSectionId = "subSectionOf-" + titleGuid;
  var $subSection = $("#" + subSectionId);
  if ($subSection.length > 0) {
    $subSection.children(".contentTitle").each(function () {
      var $childTitle = $(this);
      var childGuid = $childTitle.data("guid");
      affectedGuids.push(childGuid);
      removedGuids.push(childGuid);
    });
  }

  // Add or remove the selected class where necessary
  var setSelectedClass = function (guid, isSelected) {
    if (isSelected) {
      $("." + guid).each(function (index, element) {
        $(element).addClass("selected");
      });
    } else {
      $("." + guid).each(function (index, element) {
        $(element).removeClass("selected");
      });
    }
  };
  for (var i = 0; i < affectedGuids.length; i++) {
    setSelectedClass(affectedGuids[i], shouldBeSelected);
  }

  // If this is a child then the parent checkbox state needs to be reevaluated
  if ($clickedTitle.hasClass("child")) {
    var parentGuid = $clickedTitle.data("parentGuid");
    var $childContainer = $("#subSectionOf-" + parentGuid);
    if ($childContainer.length > 0) {
      var allSelected = true;
      var selectedChildGuids = [];
      var unselectedChildGuids = [];
      $childContainer.find(".contentTitle").each(function () {
        var isSelected = $(this).hasClass("selected");
        if (isSelected) {
          selectedChildGuids.push($(this).data("guid"));
        } else {
          allSelected = false;
          unselectedChildGuids.push($(this).data("guid"));
        }
      });
      var $parentTitles = $("." + parentGuid);
      if (allSelected) {
        // If all the children are selected, then the parent is selected
        addedGuids = [parentGuid];
        removedGuids = selectedChildGuids.concat(unselectedChildGuids);
        $parentTitles.each(function () {
          $(this).addClass("selected");
        });
      } else {
        // If even 1 child is not selected, then the parent isn't selected
        addedGuids = selectedChildGuids;
        removedGuids = [parentGuid].concat(unselectedChildGuids);
        $parentTitles.each(function () {
          $(this).removeClass("selected");
        });
      }
    }
  }

  // Loop through the added guids and:
  // 1) Add them to the list of selected guids
  // 2) Fire the hidden checkbox change event
  // 3) Get the corresponding title if we don't already have it
  for (var j = 0; j < addedGuids.length; j++) {
    var guid = addedGuids[j];
    selectedGuids.push(guid);
    selectHiddenCheckbox(guid, true);
    if (!(guid in contentTitles)) {
      contentTitles[guid] = getTitle(guid);
    }
  }

  // Filter the removed guids and any duplicates out of the selected guid list
  selectedGuids = selectedGuids.filter(function (value, index) {
        return !removedGuids.includes(value) && selectedGuids.indexOf(value) === index;
      }
  );

  // Loop through the removed guids and fire the hidden checkbox change event
  for (var k = 0; k < removedGuids.length; k++) {
    selectHiddenCheckbox(removedGuids[k], false);
  }

  updateToolbarState();
};
$(".selectionBox").click(handleSelectionClick);

var updateToolbarState = function () {
  updateToolbarButtonsState();
  $printToolbarButton.attr("href", getPrintUrl());
  $emailToolbarButton.attr("href", getEmailUrl());
};

var updateToolbarButtonsState = function () {
  var selectedCount = getSelectedGuids().length;
  var $shareButton = $("#shareButton");
  var $addNoteButton = $("#addNoteButton");
  if (selectedCount > 1) {
    $shareButton.addClass("disabled");
    $shareButton.attr("title", "You can only share one selection at a time");
    $addNoteButton.addClass("disabled");
    $addNoteButton.attr("title", "You can only add a note to one selection at a time");
  } else {
    $shareButton.removeClass("disabled");
    $shareButton.attr("title", "Share selected content");
    if (selectedGuids.length > 0 && $codeChildContent.length === 0) {
      $addNoteButton.addClass("disabled");
      $addNoteButton.attr("title", "You can not add notes to selections on pages without content");
    } else {
      $addNoteButton.removeClass("disabled");
      $addNoteButton.attr("title", "Add note to the selected part of the Code");
    }
  }
  updateCreateQuestionButtonState();
};

var updateCreateQuestionButtonState = function () {
  if (document.getSelection().toString().trim().length > 0) {
    if ($.isEmptyObject(selection)) {
      $createQuestionToolbarButton.addClass("disabled");
      $createQuestionToolbarButton.attr("title", "You can only select content within a single paragraph, footnote, or definition");
    } else {
      $createQuestionToolbarButton.removeClass("disabled");
      $createQuestionToolbarButton.attr("title", "Create Question");
    }
  } else if (selectedGuids.length > 1) {
    $createQuestionToolbarButton.addClass("disabled");
    $createQuestionToolbarButton.attr("title", "You can only create a question for one selection at a time");
  } else {
    $createQuestionToolbarButton.removeClass("disabled");
    $createQuestionToolbarButton.attr("title", "Create Question");
  }
};

var updateBreadcrumbs = function ($title) {
  var $tempSeparator = $(".temp.separator");
  var $tempBreadcrumb = $(".temp.breadcrumb");
  if ($title === null) {
    if ($tempSeparator.length > 0 || $tempBreadcrumb.length > 0) {
      $tempSeparator.remove();
      $tempBreadcrumb.remove();
      history.replaceState(
          History.getState().data,
          originalPageTitle,
          originalPathname + document.location.search
      );
    }
  } else {
    var currentGuid = $tempBreadcrumb.data("guid");
    var newGuid = $title.data("guid");
    if (currentGuid !== newGuid) {
      $tempSeparator.remove();
      $tempBreadcrumb.remove();
      var newTitle = $title.clone().find(".footnoteref").remove().end()
      .text().trim().replace(/\s+/g, " ").replace(/ chevron_right$/, '');
      var $titleLink = $title.find("a").add($title.filter("a"));
      var newHref = ($titleLink.length > 0 ? $titleLink.attr("href") : "#");
      var $newSeparator = $('<span class="material-icons material-symbols-outlined md-28 separator temp notranslate" translate="no">chevron_right</span>');
      var $newBreadcrumb = $('<a class="breadcrumb temp"/>').data("guid", newGuid).text(newTitle)
      .attr("title", newTitle).attr("href", newHref);
      $breadcrumbs.append($newSeparator);
      $breadcrumbs.append($newBreadcrumb);
      history.replaceState(
          History.getState().data,
          customerName + " " + newTitle,
          originalPathname + document.location.search + "#" + newGuid
      );
    }

  }
};

var handleWindowScroll = function () {
  if ($codeContent.length > 0 && $toolbarContainer.length > 0) {

    var contentScroll = Math.ceil($codeContent.offset().top - $toolbarContainer.outerHeight());
    var currentContentScroll = ($window.scrollTop() + contentScroll);
    var contentTitles = $codeChildContent.find(".barTitle, .mediumTitle");
    var scrolledGuid = null;
    var $scrolledContentTitle = null;
    if (contentTitles.length === 0) {
      contentTitles = $tocContent.find(".articleTitle, .tocTitle");
    }
    contentTitles.each(function () {
      if (currentContentScroll > $(this).offset().top) {
        scrolledGuid = $(this).data("guid");
        $scrolledContentTitle = $(this);
      }
    });
    if (scrolledGuid === null) {
      scrolledGuid = $body.data("guid");
      updateBreadcrumbs(null);
    } else {
      updateBreadcrumbs($scrolledContentTitle);

    }
  }
};
handleWindowScroll();
$window.scroll(handleWindowScroll);

$versionSelector.change(function (e) {
  e.preventDefault();
  var $select = $(this);
  var $option = $select.find('option:selected');
  var codeId = $option.val();
  // Set the value to empty just so we don't see a flicker of the option name before the page reloads
  $select.val("");
  if (codeId) {
    var currentHref = window.location.href;
    window.location.href = "/" + (codeId.toUpperCase() === custId.toUpperCase() ? "" : (codeId + "/")) +
        currentHref.substring(currentHref.lastIndexOf('/') + 1);
  }
});

$versionInfoIcon.click(function () {

  var displayDateValue = $body.data("display-date");
  var uploadDateValue = $body.data("upload-date");
  var codePublishingIdentifierValue = $body.data("code-publishing-identifier");
  var updatedDateValue = $body.data("updated-date");

  var $versionModal = $('<div class="versionInfoModal">');

  var $displayDate = $('<div><span>' + displayDateValue + '</span></div>');
  var $uploadDate = $('<div><span>Uploaded on: ' + uploadDateValue + '</span></div>');

  $versionModal.append($displayDate);
  $versionModal.append($uploadDate);

  if (isArchive) {
    var $archiveMessage;
    if (codePublishingIdentifierValue) {
      $archiveMessage = $('<div><span>Displaying Archived Version ' + codePublishingIdentifierValue + ' - Last Updated ' + uploadDateValue + '</span></div>');
    } else {
      $archiveMessage = $('<div><span>Displaying Archived Version: ' + updatedDateValue + '</span></div>');
    }
    $versionModal.append($archiveMessage);
  }

  window.generalcode.openDialog("versionInfoDialog", {
    title: "Version Info",
    content: $versionModal[0],
    submitLabel: "Close"
  });
});

$emailToolbarButton.click(function () {
  eventService.logEvent(
      EventCategory.SHARE,
      EventName.SHARE_CONTENT_EMAIL,
      custId,
      {type: "email", guids: getSelectedGuids()}
  );
});

var doDownload = function (e, shareGuid) {
  if (e) {
    e.preventDefault();
  }
  var wordEnabled = $body.data("wordPermission");
  var pdfEnabled = $body.data("pdfPermission");
  var exportEnabled = $body.data("exportPermission");
  var wordUserRequired = $body.data("wordLoginRequired");
  var pdfUserRequired = $body.data("pdfLoginRequired");
  var $downloadModal = $('<div class="downloadModal"></div>');
  var $downloadItems = $('<div class="followItems"></div>');
  $downloadModal.append($downloadItems);


  if (shareGuid) {
    $downloadItems.append($('<div class="followItem"></div>').text(contentTitles[shareGuid]));
  } else {
    $.each(getSelectedGuids(), function (index, guid) {
      $downloadItems.append($('<div class="followItem"></div>').text(contentTitles[guid]));
    });
  }




  var $downloadLinkContainer = $('<div class="modalLinkContainer" />');

  var $pdfDownload = $('<div id="pdfDownload" class="modalLink withIcon fileDownloadSelection" data-type="pdf"><div id="pdfDownloadIcon" class="modalLinkIcon"/><div>PDF</div></div>');
  if (shareGuid) {
    $pdfDownload.attr("data-share-guid", shareGuid);
  }
  if (pdfEnabled) {
    $pdfDownload.attr("data-disabled", false);
  } else {
    $pdfDownload.attr("data-disabled", true).addClass("disabled");
    if (user) {
      $pdfDownload.attr("data-disabled-message",
          'Download to PDF is not available for this code. Please contact <a href="mailto:customerservice@generalcode.com">General Code Customer Service</a> to gain access to this download format.');
    } else {
      if (pdfUserRequired) {
        $pdfDownload.attr("data-disabled-message",
            'Download to PDF is a feature reserved for logged-in municipal users or users of the <a href="/subscription/' + custId + '">eCode360 Subscription Service</a>. Please log in from the <a href="/user/login/' + custId + '">login page</a>');
      } else {
        $pdfDownload.attr("data-disabled-message",
            'Download to PDF is not a feature enabled for this municipality’s eCode. However, users of the <a href="/subscription/' + custId + '">eCode360 Subscription Service</a> can still gain access to this download format.');
      }
    }
  }
  $downloadLinkContainer.append($pdfDownload);

  var $wordDownload = $('<div id="wordDownload" class="modalLink withIcon fileDownloadSelection" data-type="word"><div id="wordDownloadIcon" class="modalLinkIcon"/><div>Word</div></div>');
  if (shareGuid) {
    $wordDownload.attr("data-share-guid", shareGuid);
  }
  if (wordEnabled) {
    $wordDownload.attr("data-disabled", false);
  } else {
    $wordDownload.attr("data-disabled", true).addClass("disabled");
    if (user) {
      $wordDownload.attr("data-disabled-message",
          'Download to Word is not available for this code. Please contact <a href="mailto:customerservice@generalcode.com">General Code Customer Service</a> to gain access to this download format.');
    } else {
      if (wordUserRequired) {
        $wordDownload.attr("data-disabled-message",
            'Download to Word is a feature reserved for logged-in municipal users or users of the <a href="/subscription/' + custId + '">eCode360 Subscription Service</a>. Please log in from the <a href="/user/login/' + custId + '">login page</a>');
      } else {
        $wordDownload.attr("data-disabled-message",
            'Download to Word is not a feature enabled for this municipality’s eCode. However, users of the <a href="/subscription/' + custId + '">eCode360 Subscription Service</a> can still gain access to this download format.');
      }
    }
  }
  if (!isArchive) {
    $downloadLinkContainer.append($wordDownload);
  }

  if ((getSelectedGuids().length === 1) && getSelectedGuids()[0] === custId && !isArchive && exportEnabled) {
    var $exportDownload = $('<div id="exportDownload" class="modalLink withIcon fileDownloadSelection" data-type="xml" data-disabled="false"><div id="exportDownloadIcon" class="modalLinkIcon"/><div>LegisWeb</div></div>');
    $downloadLinkContainer.append($exportDownload);
  }

  $downloadModal.append($downloadLinkContainer);

  $downloadModal.append($('<div id="downloadDisabledMessage"></div>').css({display: "none", "margin-top": "20px"}));

  window.generalcode.openDialog("downloadDialog", {
    title: "Download the selected portions of your " + ($body.data("sidebarLabel") || "Code"),
    submitLabel: "Close",
    content: $downloadModal[0]
  });

};
window.generalcode.doDownload = doDownload;
$downloadToolbarButton.click(doDownload);

$body.on("click", ".fileDownloadSelection", function () {
  if ($(this).hasClass("disabled")) {
    var $disabledMessageContainer = $("#downloadDisabledMessage");
    if ($disabledMessageContainer.length) {
      $disabledMessageContainer.show().html($(this).data("disabledMessage"));
    }
    return;
  }
  var url = "";
  if ($(this).data("share-guid")) {
    url = getDownloadUrl($(this).data("type"), [$(this).data("share-guid")]);
  } else {
    url = getDownloadUrl($(this).data("type"));
  }

  // Close the download dialog once a type has been selected
  window.generalcode.closeDialog("downloadDialog");

  // Then open the "Please wait..." dialog
  window.generalcode.openDialog("generatingDownloadDialog", {
    title: "Generating your document. Please wait...",
    actions: "<div></div>",
    content: '<img alt="Spinner icon" src="/images/Spinner.gif"/>'
  });

  var displayDownloadError = function () {
    window.generalcode.openDialog("downloadErrorDialog", {
      title: "Download Error",
      content: "Failed to generate document. Please try again."
    });
  };

  var checkCount = 0;
  $.getJSON(url).done(function (data) {
    var statusNumber = data;
    var checkStatus = function () {
      $.getJSON("/output/status/" + statusNumber).done(function (statusData) {
        if (statusData.state === "INITIALIZED") {
          window.setTimeout(checkStatus, 100 + (30000 * (checkCount / (checkCount + 1000))));
          checkCount++;
        } else {
          window.generalcode.closeDialog("generatingDownloadDialog");
          if (statusData.state === "FINISHED") {
            document.location.pathname = "/output/get/" + statusNumber;
          } else {
            if (statusData.state !== "DELETED") {
              displayDownloadError();
            }
          }
        }

      }).fail(function () {
        displayDownloadError();
      });
    };
    checkStatus();
  }).fail(function () {
    displayDownloadError();
  });
});

var openShareDialog = function (guid) {
  if (isArchive) {
    return;
  }

  var shareTitle = contentTitles[guid];
  var shareText = getShareItemContent(guid);
  var shareUrl = urlSchema + "://" + window.location.host + "/" + guid;

  var $shareModal = $('<div class="shareModal">');

  var $shareHeading = $('<div class="shareTitle">').text("How would you like to share?");

  var $facebookIcon = $('<span class="font-icon md-56 notranslate modalLinkIcon" translate="no" aria-label="Facebook icon" style="color:#3B5998;">facebook</span>');
  var facebookShareUrl = "https://www.facebook.com/dialog/share?app_id=" + $body.data("facebookAppId");
  facebookShareUrl += "&display=page&href=" + encodeURIComponent(shareUrl);
  facebookShareUrl += "&redirect_uri=" + encodeURIComponent(window.location);
  var $facebookShareLink = $('<a id="shareViaFacebook" target="_blank" rel="noopener noreferrer"/>').text("Facebook")
  .prepend($facebookIcon).addClass("modalLink").addClass("withIcon").attr("href", facebookShareUrl);
  $facebookShareLink.click(function () {
    eventService.logEvent(EventCategory.SHARE, EventName.SHARE_CONTENT_FACEBOOK, custId, {
      type: "facebook",
      guids: [guid]
    });
  });

  var $twitterIcon = $('<span class="font-icon md-56 notranslate modalLinkIcon" translate="no" aria-label="Twitter icon" style="color:#55ACEE;">twitter</span>');
  var $twitterShareLink = $('<a id="shareViaTwitter" target="_blank" rel="noopener noreferrer"/>').text("Twitter")
  .prepend($twitterIcon).addClass("modalLink").addClass("withIcon")
  .attr("href", "https://twitter.com/intent/tweet?text=" + shareText);
  $twitterShareLink.click(function () {
    eventService.logEvent(EventCategory.SHARE, EventName.SHARE_CONTENT_TWITTER, custId, {
      type: "twitter",
      guids: [guid]
    });
  });

  var $printIcon = $('<span class="material-icons material-symbols-outlined material-symbols-and-filled md-66 notranslate modalLinkIcon" translate="no" aria-label="Print icon">print</span>');
  var $printLink = $('<a id="shareViaPrint" target="_blank" rel="noopener noreferrer"/>').text("Print")
  .prepend($printIcon).addClass("modalLink").addClass("withIcon").attr("href", getPrintUrl([guid]));

  var $emailIcon = $('<span class="material-icons material-symbols-outlined material-symbols-and-filled md-66 notranslate modalLinkIcon" translate="no" aria-label="Email icon">email</span>');
  var $emailLink = $('<a id="shareViaEmail" target="_blank" rel="noopener noreferrer"/>').text("Email")
  .prepend($emailIcon).addClass("modalLink").addClass("withIcon").attr("href", getEmailUrl([guid]));

  var $downloadIcon = $('<span class="material-icons material-symbols-outlined material-symbols-and-filled md-66 notranslate modalLinkIcon" translate="no" aria-label="Download icon">download</span>');
  var $downloadLink = $('<a id="shareViaDownload" target="_blank" rel="noopener noreferrer"/>').text("Download")
  .prepend($downloadIcon).addClass("modalLink").addClass("withIcon");

  $downloadLink.click(function () {
    doDownload(null, guid);
  });


  var $shareLinkContainer = $('<div class="modalLinkContainer" />');
  $shareLinkContainer.append($facebookShareLink);
  $shareLinkContainer.append($twitterShareLink);
  $shareLinkContainer.append($printLink);
  $shareLinkContainer.append($emailLink);
  $shareLinkContainer.append($downloadLink);

  var $contentUrlCopyBox = $('<div id="linkBox"/>');
  var $contentUrlCopyInput = $('<input type="text" id="linkCopy" readonly />').val(shareUrl);
  $contentUrlCopyInput.click(function () {
    $contentUrlCopyInput.select();
    document.execCommand("copy");
  });
  var $contentUrlCopyButton = $('<button id="linkCopyButton"/>').text("Copy Link");
  $contentUrlCopyButton.click(function () {
    $contentUrlCopyInput.select();
    document.execCommand("copy");
  });
  var $contentUrlCopyInputContainer = $('<div class="linkBoxInputContainer"/>');
  $contentUrlCopyInputContainer.append($contentUrlCopyInput);
  $contentUrlCopyInputContainer.append($contentUrlCopyButton);
  var $contentUrlCopyDescription = $("<div/>").text("Ctrl+V to paste it into your desired location.");
  $contentUrlCopyBox.append($contentUrlCopyInputContainer);
  $contentUrlCopyBox.append($contentUrlCopyDescription);

  var $embedLink = $("<a />").attr("href", shareUrl).attr("target", "_self").text(shareTitle);
  var $embedLinkBox = $('<div id="embedBox"/>');
  var $embedLinkBoxHeader = $('<div class="shareEmbedTitle"/>').text("Embed");
  var $newWindowCheckboxBox = $("<div/>");
  var $newWindowCheckbox = $('<input type="checkbox" id="embedInNewWindow"/>');
  $newWindowCheckbox.change(function () {
    $embedLink.attr("target", (this.checked ? "_blank" : "_self"));
    $embedLinkCopyInput.val($embedLink[0].outerHTML);
  });
  var $newWindowLabel = $('<label for="embedInNewWindow"/>').text(" Open in New Window");
  $newWindowCheckboxBox.append($newWindowCheckbox);
  $newWindowCheckboxBox.append($newWindowLabel);
  var $embedLinkCopyBox = $("<div />");
  var $embedLinkCopyInput = $('<input type="text" id="embedCopy"  readonly />').val($embedLink[0].outerHTML);
  $embedLinkCopyInput.click(function () {
    $embedLinkCopyInput.select();
    document.execCommand("copy");
  });
  var $embedLinkCopyButton = $('<button id="embedCopyButton" />').text("Copy Html");
  $embedLinkCopyButton.click(function () {
    $embedLinkCopyInput.select();
    document.execCommand("copy");
  });
  var $embedLinkCopyInputContainer = $('<div class="linkBoxInputContainer"/>');
  $embedLinkCopyInputContainer.append($embedLinkCopyInput);
  $embedLinkCopyInputContainer.append($embedLinkCopyButton);
  var $embedLinkCopyDescription = $("<div />").text("Ctrl+V to paste it into your desired location.");
  $embedLinkCopyBox.append($embedLinkCopyInputContainer);
  $embedLinkCopyBox.append($embedLinkCopyDescription);
  $embedLinkBox.append($embedLinkBoxHeader);
  $embedLinkBox.append($newWindowCheckboxBox);
  $embedLinkBox.append($embedLinkCopyBox);

  $shareModal.append($shareHeading);
  $shareModal.append($shareLinkContainer);
  $shareModal.append($contentUrlCopyBox);
  $shareModal.append($embedLinkBox);

  window.generalcode.openDialog("shareDialog", {
    title: "You are sharing: " + shareTitle,
    width: "md",
    submitLabel: "Close",
    content: $shareModal[0]
  });
};

var shareButtonClick = function (e) {
  if (e) {
    e.preventDefault();
  }
  if ($(this).hasClass("disabled")) {
    return;
  }
  var guids = getSelectedGuids();
  if (guids.length !== 1) {
    window.generalcode.openDialog("shareTooManyErrorDialog", {
      title: "Too Many Selections",
      content: "Please select a single selection. You can only share one selection at a time."
    });
    return;
  }
  openShareDialog(guids[0]);
};
window.generalcode.shareButtonClick = shareButtonClick;
$shareToolbarButton.click(shareButtonClick);

var alertsButtonClick = function (e) {
  if (e) {
    e.preventDefault();
  }

  var $followModal = $('<div class="followModalContainer">');
  var $followItems = $('<div class="followItems"></div>');

  var guids = getSelectedGuids();
  $.each(guids, function (index, guid) {
    $followItems.append($('<div class="followItem"/>').text(contentTitles[guid]));
  });

  var $followEmailBox = $('<div id="emailBox" />');
  var $followEmailInput = $('<input type="email" id="followEmailAddress" aria-label="Your email address" placeholder="Your email address" value="' + (user ? user : "") + '" style="width: 100%;"/>');
  var $followEmailLoading = $('<div id="followEmailWorking" />').text("Signing you up...");
  var loading = false;
  var requestNotification = function () {
    if (!loading) {
      var $followEmailInput = $("#followEmailAddress");
      var emailAddress = $followEmailInput.length ? $followEmailInput.val() : "";
      if (emailAddress.match(/[^@]+@[^@]+\.[^@]+/)) {
        loading = true;
        $followEmailBox.addClass("working");
        $.ajax({
          email: emailAddress,
          url: "/user/notification/create",
          type: "POST",
          data: {
            email: emailAddress,
            guids: guids
          },
          success: function () {
            loading = false;
            $followEmailBox.removeClass("working");
            window.generalcode.openDialog("eAlertSuccessDialog", {
              title: "eAlert Subscription Request Received",
              content: "Please check your " + emailAddress + " email account to confirm your eAlert subscription request."
            });
          },
          error: function () {
            loading = false;
            $followEmailBox.removeClass("working");
            window.generalcode.openDialog("eAlertErrorDialog", {
              title: "eAlert Error",
              content: "Something went wrong. Please try again. " +
                  "If you continue to have problems, please contact General Code's Client Services Team at " +
                  "<a class='xref' href='mailto:ecodehelp@generalcode.com'>eCodehelp@generalcode.com</a>."
            });
          }
        });
      } else {
        window.generalcode.openDialog("eAlertInvalidEmailDialog", {
          title: "Invalid Email",
          content: "Please try again using a valid email address"
        });
      }
    }
  };
  $followEmailBox.append($followEmailInput);
  $followEmailBox.append($followEmailLoading);

  $followModal.append($followItems);
  $followModal.append($followEmailBox);

  window.generalcode.openDialog("eAlertDialog", {
    title: "Sign up for automatic email alerts (eAlerts) when the following Code content is updated:",
    content: $followModal[0],
    width: "md",
    submitLabel: "Sign Up",
    onSubmit: requestNotification,
    cancelLabel: "Cancel",
    onCancel: function () {
    }
  });
};
$alertsToolbarButton.click(alertsButtonClick);
window.generalcode.alertButtonClick = alertsButtonClick;

var selection = {};
$(document).on("selectionchange", function () {
  selection = {};
  if (!$body[0].dataset.ia2Url) {
    return;
  }
  var selectedText = document.getSelection().toString();
  if (selectedText && selectedText.length > 0) {
    var paragraphSelector = ".para, .deftext, .ftpara";
    var $startPara = $(document.getSelection().anchorNode).closest(paragraphSelector);
    var $endPara = $(document.getSelection().focusNode).closest(paragraphSelector);
    if ($startPara[0] && $startPara[0] === $endPara[0]) {
      var fullContext = $startPara.clone().children().remove(".history").remove(".footnoteref").end().text().trim().replace(/\s+/g, " ");
      if (fullContext && fullContext.length > 0 && fullContext.indexOf(selectedText) >= 0) {
        var offset = fullContext.indexOf(selectedText);
        selection.selection = selectedText;
        selection.precontext = fullContext.substring(0, offset);
        selection.postcontext = fullContext.substring(offset + selectedText.length);
      } else {
        selection.selection = selectedText;
        selection.precontext = "";
        selection.postcontext = "";
      }

      var parentSelector = ".definition, .litem, .defitem, .section_content, .subarticle_content, .article_content, .chapter_content";
      var $parent = $startPara.closest(parentSelector);
      if ($parent.hasClass("litem")) {
        selection.guid = $parent[0].id;
      } else if ($parent.hasClass("definition")) {
        selection.guid = $parent[0].id;
      } else {
        selection.guid = $parent[0].id.replace("_content", "");
      }
      updateToolbarState();
      return;
    }
  }
  updateToolbarState();
});

var createManuscript = function (guid) {
  var url = "/content/manuscript?guid=" + guid;
  if (selection.selection) {
    url += "&selection=" + encodeURIComponent(selection.selection);
  }
  $.ajax({
    type: "GET",
    url: url,
    success: function (manuscript) {
      manuscript.selection = selection.selection;
      manuscript.precontext = selection.precontext;
      manuscript.postcontext = selection.postcontext;
      createAndOpenQuestion(manuscript);
      selectedGuids = [];
      selection = {};
      $(".selected.barTitle,.selected.mediumTitle").removeClass("selected");
      document.getSelection().empty();
    },
    error: function () {
      window.generalcode.openDialog("questionCreateErrorDialog", {
        title: "Unknown Error",
        content: "Something went wrong while creating the question."
      });
    }
  });
};

var createAndOpenQuestion = function (manuscript) {
  var ia2Url = $body[0].dataset.ia2Url;
  $.ajax({
    type: "POST",
    url: ia2Url + "/data/newContent",
    data: JSON.stringify(manuscript),
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    success: function (id) {
      window.open(ia2Url + "/create?id=" + id, "newIa2Question");
    },
    error: function () {
      window.generalcode.openDialog("questionCreateErrorDialog", {
        title: "Unknown Error",
        content: "Something went wrong while creating the question."
      });
    }
  });
};

var getHrefGuid = function (href) {
  if (href.indexOf("#") >= 0) {
    return href.substring(href.indexOf("#") + 1);
  } else if (href.charAt(0) === "/") {
    return href.substring(1);
  }
};

var subsectionShareDialog = function (event, element) {
  if (element.is(".litem_number,.defitem_number")) {
    event.preventDefault();
    var guid = element.parent().attr("id");
    contentTitles[guid] = getTitle(guid);


    openShareDialog(guid);
  }
};

$(".litem_number,.defitem_number,.termLink,.titleLink").click(function (e) {
  if (e.shiftKey) {
    if (!$body[0].dataset.ia2Url) {
      subsectionShareDialog(e, $(this));
      return;
    }
    e.preventDefault();
    e.stopImmediatePropagation();
    if (!isArchive && ($body.data("ia2-enabled") || $body.data("ia2-default-enabled")) && $body.data("ia2-permission")) {
      createManuscript(getHrefGuid($(this).attr("href")));
    }
  } else {
    subsectionShareDialog(e, $(this));
  }
});

$createQuestionToolbarButton.click(function (e) {
  e.preventDefault();
  if ($(this).hasClass("disabled")) {
    return;
  }
  var guid;
  if (document.getSelection().toString().trim().length > 0) {
    if ($.isEmptyObject(selection)) {
      window.generalcode.openDialog("questionsInvalidSelectionErrorDialog", {
        title: "Unknown Error",
        content: "This selection is not valid for creating a question. " +
            "Please select content within a single paragraph, footnote, or definition."
      });
      return;
    } else {
      guid = selection.guid;
    }
  } else {
    if (getSelectedGuids().length !== 1) {
      window.generalcode.openDialog("questionsTooManyErrorDialog", {
        title: "Unknown Error",
        content: "Please select a single selection. You can only create a question for one selection at a time."
      });
      return;
    }
    guid = getSelectedGuids()[0];
  }
  createManuscript(guid);
});

var goNext = function (e) {
  if ($(".lightbox").length === 0 && (e.type !== "swipeleft" || $(e.target).parents("table").length === 0 ||
      $(e.target).parents("table").parent().css("overflow-x") !== "auto")
  ) {
    e.preventDefault();
    var $next = $("#resultContextNext");
    if ($next.length === 0) {
      $next = $("#gpsNext");
    }
    if ($next.length > 0) {
      $next[0].click();
    }
  }
};

var goPrev = function (e) {
  if ($(".lightbox").length === 0 && (e.type !== "swiperight" || $(e.target).parents("table").length === 0 ||
      $(e.target).parents("table").parent().css("overflow-x") !== "auto")
  ) {
    e.preventDefault();
    var $prev = $("#resultContextPrev");
    if ($prev.length === 0) {
      $prev = $("#gpsPrev");
    }
    if ($prev.length > 0) {
      $prev[0].click();
    }
  }
};

var pageUp = function () {
  var toolbarHeight = $warningsBar.height() + $toolbarContainer.outerHeight() +
      $searchBar.outerHeight() + $body.position().top;
  var scrollDistance = $window.height() - toolbarHeight;
  window.scrollTo(window.pageXOffset, window.pageYOffset - scrollDistance);
};

var pageDown = function () {
  var toolbarHeight = $warningsBar.height() + $toolbarContainer.outerHeight() +
      $searchBar.outerHeight() + $body.position().top;
  var scrollDistance = $window.height() - toolbarHeight;
  window.scrollTo(window.pageXOffset, window.pageYOffset + scrollDistance);
};

$(document).keydown(function (e) {
  if (!$(".modal:visible").length && !$(document.activeElement).is("input") && !document.activeElement.isContentEditable) {
    switch (e.which) {
      case 33: // Page Up
        pageUp();
        break;
      case 34: //Page Down
        pageDown();
        break;
      case 37: // Left Arrow
        goPrev(e);
        break;
      case 39: // Right Arrow
        goNext(e);
        break;
      default:
        return;
    }
  }
});

if ($body.data("trackbackEnabled") === true && $body.data("trackbackLawLedgerView") === true) {
  var createTrackback = function ($legref, className, reference) {
    var $trackback = $("<a />");
    $trackback.addClass(className);
    $trackback.attr("target", "_blank");
    $trackback.attr("href", "/" + custId + "/laws/" + reference + ".pdf");
    $trackback.text($legref.text());
    $legref.replaceWith($trackback);
  };
  $(".legref").each(function () {
    if ($(this).data("ref")) {
      var $legref = $(this);
      var reference = $legref.data("ref");
      var $replaceElement = ($legref.children(".loclaw").length > 0 ? $legref.children(".loclaw") : $legref);
      var classNames = ["xref"];
      $replaceElement.children().addBack().each(function () {
        var childClasses = $(this).attr("class") ? $(this).attr("class").split(/\s+/) : [];
        for (var i = 0; i < childClasses.length; i++) {
          var childClass = childClasses[i];
          if (classNames.indexOf(childClass) === -1) {
            classNames.push(childClass);
          }
        }
      });
      createTrackback($replaceElement, classNames.join(" "), reference);
    }
  });
}

$(".attachmentslink").click(function (e) {
  e.preventDefault();
  var href = $(this).attr("href");
  var guid = href.substring((href.lastIndexOf("/") + 1), href.indexOf("#"));
  var url = "/api/" + custId + "/code/" + codeId + "/attachments/" + guid;
  $.ajax({
    type: "GET",
    url: url,
    success: function (response) {
      var $attachmentsModal = $('<div class="attachmentsModalContent"/>');
      var $attachmentsContainer = $('<div class="attachmentsContainer"/>');
      if (response.attachments && response.attachments.length > 0) {
        for (var i = 0; i < response.attachments.length; i++) {
          var attachment = response.attachments[i];
          var attachmentName = attachment.filename.endsWith("." + attachment.extension) ?
              attachment.filename.substring(0, attachment.filename.length - (attachment.extension.length + 1)) :
              attachment.filename;
          var attachmentTitle = "";
          if (attachment.title) {
            attachmentTitle = attachment.title;
          }
          var $attachment = $('<a class="nonxml" target="_blank" />');
          if (isArchive) {
            $attachment.attr("href", ("/attachment/" + codeId + "/" + attachmentName + "." + attachment.extension));
          } else {
            $attachment.attr("href", ("/attachment/" + custId + "/" + attachmentName + "." + attachment.extension));
          }
          if (attachmentTitle !== "") {
            $attachment.html(attachmentTitle + '<img src="/images/pdf.gif" alt="PDF icon"/>');
          } else {
            $attachment.html(attachmentName + '<img src="/images/pdf.gif" alt="PDF icon"/>');
          }
          $attachmentsContainer.append($attachment);
        }
      } else {
        $attachmentsContainer.text("There are no attachments under " + response.content.label + " " +
            response.content.number + " " + response.content.title);
      }
      $attachmentsModal.append($attachmentsContainer);
      window.generalcode.openDialog("attachmentsDialog", {
        title: (
            "<div class='customerTitleColor'>" +
            "<span class='titleNumber'>" +
            response.content.label + " " + response.content.number +
            "</span>" +
            "<span class='titleTitle'>" +
            response.content.title +
            "</span>" +
            "</div>" +
            "<div class='attachmentsTitle'>ATTACHMENTS</div>"
        ),
        content: $attachmentsModal[0],
        submitLabel: "Close",
        width: "md"
      });
    },
    error: function () {
      window.open(href, "_blank");
    }
  });
});

// If the table has more than 30 rows, then float its header
$(".codeTable:not(.landTable) table").filter(function () {
  return $(this).find("tbody").children().size() > 30 && $(this).find("thead").children().size() > 0;
}).addClass("stickyTable");

if (typeof $.cookie === "function") {
  if ($.cookie("mode") === "touch") {
    window.location.reload(true);
  }
  if (custId && !user && $body.data("subscriptionQuestion")) {
    var custIds = $.cookie("custIds");
    if (!(custIds) || custIds === "") {
      custIds = custId;
    } else if (custIds.indexOf(custId) === -1) {
      custIds = custIds + ',' + custId;
    }
    $.cookie("custIds", custIds, {expires: 365});

    // If the person has viewed 2 or more codes and they're not logged in, give them the subscription pitch
    if (custIds.indexOf(",") !== custIds.lastIndexOf(",")) {
      var shown = $.cookie("marketingShown");
      if (!shown) {
        $.cookie("marketingShown", true, {expires: 365});
        eventService.logEvent(
            EventCategory.SUBSCRIPTION,
            EventName.SUBSCRIPTION_QUESTION_ASKED,
            custId,
            {custIds: custIds}
        );
        window.generalcode.openDialog("subscriptionQuestionDialog", {
          content: $body.data("subscriptionQuestion"),
          submitLabel: "Yes",
          onSubmit: function () {
            window.open($body.data("subscriptionPurchaseUrl"), "_blank");
          },
          cancelLabel: "No",
          onCancel: function () {
            eventService.logEvent(
                EventCategory.SUBSCRIPTION,
                EventName.SUBSCRIPTION_QUESTION_DECLINED,
                custId,
                {custIds: custIds}
            );
          },
        });
      }
    }
  }
}

if (window.hasOwnProperty && window.hasOwnProperty("Modernizr")) {
  Modernizr.load({
    test: Modernizr.touch,
    yep: "/js/jquery.mobile.custom-min.js",
    complete: function () {
      if (typeof $.event.special.swipe === "function") {
        $.event.special.swipe.durationThreshold = 500;
        $.event.special.swipe.verticalDistanceThreshold = 40;
        $.event.special.swipe.horizontalDistanceThreshold = screen.availWidth / 2;
        $window.on("swipeleft.nav", goNext);
        $window.on("swiperight.nav", goPrev);
      }
    }
  });
}

window.addEventListener("beforeprint", function () {
  eventService.logEvent(EventCategory.PRINT, EventName.PRINT_CONTENT, custId, {type: "browser", guids: [custId]});
});

$codeContent.find(".landTable:not(.lightbox)").click(function () {
  var $tableContainer = $(this);
  var $tableContainerClone = $tableContainer.clone();
  $tableContainerClone.addClass("lightbox");
  var $tableClone = $tableContainerClone.find("table");
  $tableClone.addClass("stickyTable");
  var title = null;
  var $tableTitle = $tableContainerClone.find(".tableTitle");
  if ($tableTitle.length) {
    title = $tableTitle.text();
    $tableTitle.remove();
  }
  $tableClone.unwrap();
  window.generalcode.openDialog("codeTableDialog", {
    title: (!title ? " " : title),
    fullWidth: true,
    content: $tableContainerClone[0],
    DialogContentProps: {style: {paddingTop: 0}},
    submitLabel: "Close"
  });
});

window.generalcode.scrollToHash = function () {
  var pathname = window.location.pathname;
  var hash = window.location.hash;
  var $hashElement = $(hash);
  if ($hashElement.length === 0) {
    var requestedGuid = getHrefGuid(pathname + hash);
    if (requestedGuid.indexOf("/") === -1) {
      hash = "#" + requestedGuid;
      $hashElement = $(hash);
    }
  }
  if ($hashElement.length > 0) {
    setTimeout(function () {
      $hashElement[0].scrollIntoView();
    }, 10);

    // Focus the requested piece of content for accessibility
    var focusableSelector = "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";
    var $focusableContent = $hashElement.find(focusableSelector).addBack(focusableSelector);
    if ($focusableContent.length > 0) {
      $focusableContent[0].focus();
    }
  }
};

// If there's a search highlight, scroll to it
// Otherwise, scroll the requested content into view
var url = new URL(window.location.href);
if (url.searchParams.has("highlight") && url.searchParams.get("highlight")) {
  var searchGuid = SERVER_DATA && SERVER_DATA.searchResultsGuid;
  setTimeout(function () {
    var $searchContentHighlights = $("#" + searchGuid + " .highlight, #" + searchGuid + "_content .highlight");
    if ($searchContentHighlights.length > 0) {
      $searchContentHighlights[0].scrollIntoView();
    } else {
      window.generalcode.scrollToHash();
    }
  }, 100);
} else {
  setTimeout(function () {
    window.generalcode.scrollToHash();
  }, 100);
}


function EventService() {
  this.logEvent = function (eventCategory, eventName, custId, eventInfo) {
    var eventInfoString = JSON.stringify(eventInfo);
    $.post("/event", {eventName: eventName, custId: custId, eventInfo: eventInfoString});
    window.generalcode.logAnalyticsEvent(eventName, {
      "event_category": (eventCategory ? eventCategory : "generic"),
      "event_label": custId,
      "event_info": eventInfoString
    });
  };
}

$(".archiveManageButton").click(function (e) {
  var $manageDialog = $('<div id="archiveManageDialog" data-version-id="' + e.target.dataset.versionId + '"/>');

  var $displayDateField = $('<div class="archiveModalLine"><label for="displayDateInput">Display Date: </label><input type="text" id="displayDateInput" value="' + e.target.dataset.versionDisplayDate + '"></div>');

  var date = new Date(Date.parse(e.target.dataset.versionUpdatedDate));
  var dateString = (date.getMonth() + 1).toString() + '-' + (date.getDate()) + '-' + date.getFullYear();
  var $updatedDateField = $('<div class="archiveModalLine"><label for="updatedDateInput">Updated Date (in format MM-DD-YYYY): </label><input type="text" id="updatedDateInput" value="' + dateString + '"></div>');

  var $publishingIdentifierField = $('<div class="archiveModalLine"><label for="publishingIdentifierInput">Publishing Identifier: </label><input type="text" id="publishingIdentifierInput" value="' + e.target.dataset.versionPublishingIdentifier + '"></div>');

  $manageDialog.append($displayDateField);

  $manageDialog.append($updatedDateField);

  $manageDialog.append($publishingIdentifierField);

  window.generalcode.openDialog("versionManageDialog", {
    title: "Version Manage",
    content: $manageDialog[0],
    cancelLabel: "Cancel",
    submitLabel: "Submit",
    width: "md",
    onSubmit: function () {
      var displayDate = $("#displayDateInput")[0].value;
      var updatedDate = $("#updatedDateInput")[0].value;
      var publishingIdentifier = $("#publishingIdentifierInput")[0].value;

      var versionId = $('#archiveManageDialog')[0].dataset.versionId;

      $.ajax({
        url: "/archives/manage/" + versionId,
        type: "POST",
        data: {
          displayDate: displayDate,
          updatedDate: updatedDate,
          publishingIdentifier: publishingIdentifier
        },
        success: function () {
          window.location.reload();
        },
        error: function (response) {
          window.generalcode.openDialog("archiveManageErrorDialog", {
            title: "Archive Manage Error",
            content: "Something went wrong : " + response.responseJSON.message
          });
        }
      });
    },
    onCancel: function () {
      window.generalcode.closeDialog("versionManageDialog");
    }
  });
});

$('#enableAllVersions').change(function (e) {
  if(this.checked) {
    $('#toc .nonDisplayVersion').css('display', 'flex');
  } else {
    $('#toc .nonDisplayVersion').css('display', 'none');
  }
});

window.generalcode.refreshNoteLocations = function () {
  $(".notesTitle, #notesPage .notes").each(function () {
    var notes = $(this).find(".noteContainer");
    if (notes[0]) {
      $(this).show();
    } else {
      $(this).hide();
    }
  });
  if (!$(".noteContainer")[0]) {
    $("#noNotesMessage").removeClass("hide");
  }
};

